import React from 'react';

import Layout from '../../layout/CompanyLayout';
import ProjectListView from '../../views/company/ProjectListView';
const ProjectList = (): React.ReactElement => {
  return (
    <Layout>
      <ProjectListView></ProjectListView>
    </Layout>
  );
};

export default ProjectList;
